import {
  useWindowSize
} from "usehooks-ts";
import {
  DoubleEliminationBracket,
  Match,
  SVGViewer,
  createTheme,
} from "@g-loot/react-tournament-brackets";
import "./styles.css";

export default function App() {
  return <DoubleElimination />;
}

export const DoubleElimination = () => {
  const {width, height} = useWindowSize();
  const finalWidth = Math.max(width - 50, 500);
  const finalHeight = Math.max(height - 100, 500);
  return (
  <DoubleEliminationBracket
    theme={GlootTheme}
    matches={simpleDoubleFull}
    matchComponent={Match}
    svgWrapper={({ children, ...props }) => (
      <SVGViewer
        width={finalWidth}
        height={finalHeight}
        background="rgb(11, 13, 19)"
        SVGBackground="rgb(11, 13, 19)"
        {...props}
      >
        {children}
      </SVGViewer>
    )}
    onMatchClick={(match) => console.log(match)}
    onPartyClick={(match) => console.log(match)}
  />);
};

const GlootTheme = createTheme({
  textColor: { main: "#000000", highlighted: "#F4F2FE", dark: "#707582" },
  matchBackground: { wonColor: "#2D2D59", lostColor: "#1B1D2D" },
  score: {
    background: {
      wonColor: `#10131C`,
      lostColor: "#10131C"
    },
    text: { highlightedWonColor: "#7BF59D", highlightedLostColor: "#FB7E94" }
  },
  border: {
    color: "#292B43",
    highlightedColor: "RGBA(152,82,242,0.4)"
  },
  roundHeader: { backgroundColor: "#3B3F73", fontColor: "#F4F2FE" },
  connectorColor: "#3B3F73",
  connectorColorHighlight: "RGBA(152,82,242,0.4)",
  svgBackground: "#0F121C"
});

//import { Match } from '../types';

//const simpleDoubleFull: { upper: Match[]; lower: Match[] } = {
const simpleDoubleFull = {
  upper: [
    {
      id: 20512,
      name: 'UB Semi Final',
      nextMatchId: 20515,
      nextLooserMatchId: 20502,
      tournamentRoundText: 'UB 3',
      startTime: 'August 05, 2021',
      state: 'SCORE_DONE',
      participants: [
        {
          id: 'a3c107d2-ded2-4f33-85e7-2215805f664b',
          resultText: '0',
          isWinner: false,
          status: 'PLAYED',
          name: 'BTC',
        },
        {
          id: 'a3fb4b05-d4ee-4efe-84cf-b500cdbdbbe0',
          resultText: '2',
          isWinner: true,
          status: 'PLAYED',
          name: 'Towby',
        },
      ],
    },
    {
      id: 20513,
      name: 'UB 2.1',
      nextMatchId: 20512,
      nextLooserMatchId: 20506,
      tournamentRoundText: 'UB 2',
      startTime: 'August 05, 2021',
      state: 'SCORE_DONE',
      participants: [
        {
          id: '8cf422cd-a99d-4184-b2cd-70ee481f46b3',
          resultText: '0',
          isWinner: false,
          status: 'PLAYED',
          name: 'GlootOne',
        },
        {
          id: 'a3fb4b05-d4ee-4efe-84cf-b500cdbdbbe0',
          resultText: '2',
          isWinner: true,
          status: 'PLAYED',
          name: 'Towby',
        },
      ],
    },
    {
      id: 20514,
      name: 'UB 1.2',
      nextMatchId: 20513,
      nextLooserMatchId: 20505,
      tournamentRoundText: 'UB 1',
      startTime: 'August 05, 2021',
      state: 'SCORE_DONE',
      participants: [
        {
          id: 'a3fb4b05-d4ee-4efe-84cf-b500cdbdbbe0',
          resultText: '2',
          isWinner: true,
          status: 'PLAYED',
          name: 'Towby',
        },
        {
          id: '12046e66-adbf-49d9-98c1-bed16d5ced29',
          resultText: '1',
          isWinner: false,
          status: 'PLAYED',
          name: 'Alex',
        },
      ],
    },
    {
      id: 20515,
      name: 'Final',
      nextMatchId: null,
      nextLooserMatchId: null,
      tournamentRoundText: 'UB 4',
      startTime: 'August 05, 2021',
      state: 'SCORE_DONE',
      participants: [
        {
          id: '3d1a5ddb-85b7-4724-b94d-8747454d66e9',
          resultText: '2',
          isWinner: true,
          status: 'PLAYED',
          name: 'SeatloN',
        },
        {
          id: 'a3fb4b05-d4ee-4efe-84cf-b500cdbdbbe0',
          resultText: '1',
          isWinner: false,
          status: 'PLAYED',
          name: 'Towby',
        },
      ],
    },
    {
      id: 20508,
      name: 'UB 1.4',
      nextMatchId: 20510,
      nextLooserMatchId: 20503,
      tournamentRoundText: 'UB 1',
      startTime: 'August 05, 2021',
      state: 'SCORE_DONE',
      participants: [
        {
          id: 'dd6c4bff-80da-449c-8bfa-24c835af013a',
          resultText: '0',
          isWinner: false,
          status: 'PLAYED',
          name: 'OmarDev',
        },
        {
          id: 'a3c107d2-ded2-4f33-85e7-2215805f664b',
          resultText: '2',
          isWinner: true,
          status: 'PLAYED',
          name: 'BTC',
        },
      ],
    },
    {
      id: 20509,
      name: 'UB 1.1',
      nextMatchId: 20513,
      nextLooserMatchId: 20505,
      tournamentRoundText: 'UB 1',
      startTime: 'August 05, 2021',
      state: 'SCORE_DONE',
      participants: [
        {
          id: '8cf422cd-a99d-4184-b2cd-70ee481f46b3',
          resultText: '2',
          isWinner: true,
          status: 'PLAYED',
          name: 'GlootOne',
        },
        {
          id: 'e1e48aad-5e29-41dc-b904-16f152a7ec74',
          resultText: '0',
          isWinner: false,
          status: 'PLAYED',
          name: 'spacefudg3',
        },
      ],
    },
    {
      id: 20510,
      name: 'UB 2.2',
      nextMatchId: 20512,
      nextLooserMatchId: 20504,
      tournamentRoundText: 'UB 2',
      startTime: 'August 05, 2021',
      state: 'SCORE_DONE',
      participants: [
        {
          id: 'a3c107d2-ded2-4f33-85e7-2215805f664b',
          resultText: '2',
          isWinner: true,
          status: 'PLAYED',
          name: 'BTC',
        },
        {
          id: '3d1a5ddb-85b7-4724-b94d-8747454d66e9',
          resultText: '1',
          isWinner: false,
          status: 'PLAYED',
          name: 'SeatloN',
        },
      ],
    },
    {
      id: 20511,
      name: 'UB 1.3',
      nextMatchId: 20510,
      nextLooserMatchId: 20503,
      tournamentRoundText: 'UB 1',
      startTime: 'August 05, 2021',
      state: 'SCORE_DONE',
      participants: [
        {
          id: '3d1a5ddb-85b7-4724-b94d-8747454d66e9',
          resultText: '2',
          isWinner: true,
          status: 'PLAYED',
          name: 'SeatloN',
        },
        {
          id: 'fabb2218-49d6-41a2-874c-d6cd6928df70',
          resultText: '1',
          isWinner: false,
          status: 'PLAYED',
          name: 'jackieboi',
        },
      ],
    },
  ],
  lower: [
    {
      id: 20502,
      name: 'LB Semi Final',
      nextMatchId: 20515,
      nextLooserMatchId: null,
      tournamentRoundText: 'LB 4',
      startTime: 'August 05, 2021',
      state: 'SCORE_DONE',
      participants: [
        {
          id: 'a3c107d2-ded2-4f33-85e7-2215805f664b',
          resultText: '1',
          isWinner: false,
          status: 'PLAYED',
          name: 'BTC',
        },
        {
          id: '3d1a5ddb-85b7-4724-b94d-8747454d66e9',
          resultText: '2',
          isWinner: true,
          status: 'PLAYED',
          name: 'SeatloN',
        },
      ],
    },
    {
      id: 20503,
      name: 'LB 1.2',
      nextMatchId: 20506,
      nextLooserMatchId: null,
      tournamentRoundText: 'LB 1',
      startTime: 'August 05, 2021',
      state: 'SCORE_DONE',
      participants: [
        {
          id: 'dd6c4bff-80da-449c-8bfa-24c835af013a',
          resultText: '0',
          isWinner: false,
          status: 'PLAYED',
          name: 'OmarDev',
        },
        {
          id: 'fabb2218-49d6-41a2-874c-d6cd6928df70',
          resultText: '2',
          isWinner: true,
          status: 'PLAYED',
          name: 'jackieboi',
        },
      ],
    },
    {
      id: 20504,
      name: 'LB 2.1',
      nextMatchId: 20507,
      nextLooserMatchId: null,
      tournamentRoundText: 'LB 2',
      startTime: 'August 05, 2021',
      state: 'SCORE_DONE',
      participants: [
        {
          id: '3d1a5ddb-85b7-4724-b94d-8747454d66e9',
          resultText: '2',
          isWinner: true,
          status: 'PLAYED',
          name: 'SeatloN',
        },
        {
          id: 'e1e48aad-5e29-41dc-b904-16f152a7ec74',
          resultText: '0',
          isWinner: false,
          status: 'PLAYED',
          name: 'spacefudg3',
        },
      ],
    },
    {
      id: 20505,
      name: 'LB 1.1',
      nextMatchId: 20504,
      nextLooserMatchId: null,
      tournamentRoundText: 'LB 1',
      startTime: 'August 05, 2021',
      state: 'SCORE_DONE',
      participants: [
        {
          id: 'e1e48aad-5e29-41dc-b904-16f152a7ec74',
          resultText: '2',
          isWinner: true,
          status: 'PLAYED',
          name: 'spacefudg3',
        },
        {
          id: '12046e66-adbf-49d9-98c1-bed16d5ced29',
          resultText: '0',
          isWinner: false,
          status: 'PLAYED',
          name: 'Alex',
        },
      ],
    },
    {
      id: 20506,
      name: 'LB 2.2',
      nextMatchId: 20507,
      nextLooserMatchId: null,
      tournamentRoundText: 'LB 2',
      startTime: 'August 05, 2021',
      state: 'SCORE_DONE',
      participants: [
        {
          id: 'fabb2218-49d6-41a2-874c-d6cd6928df70',
          resultText: '1',
          isWinner: false,
          status: 'PLAYED',
          name: 'jackieboi',
        },
        {
          id: '8cf422cd-a99d-4184-b2cd-70ee481f46b3',
          resultText: '2',
          isWinner: true,
          status: 'PLAYED',
          name: 'GlootOne',
        },
      ],
    },
    {
      id: 20507,
      name: 'LB 3.1',
      nextMatchId: 20502,
      nextLooserMatchId: null,
      tournamentRoundText: 'LB 3',
      startTime: 'August 05, 2021',
      state: 'SCORE_DONE',
      participants: [
        {
          id: '3d1a5ddb-85b7-4724-b94d-8747454d66e9',
          resultText: '2',
          isWinner: true,
          status: 'PLAYED',
          name: 'SeatloN',
        },
        {
          id: '8cf422cd-a99d-4184-b2cd-70ee481f46b3',
          resultText: '0',
          isWinner: false,
          status: 'PLAYED',
          name: 'GlootOne',
        },
      ],
    },
  ],
};
//export default simpleDoubleFull;